<template>
  <div>
    <mega-modal
      ref="modal-2"
      class="modal-sm"
      :active="modal"
      :loading="!loaded"
      @onClose="modal = false"
    >
      <mega-header title="Edit group" class="bg-lighter">
        <span class="text-muted">Enter group details.</span>
      </mega-header>

      <div class="card-body">
        <mega-input label="Group Name" v-model="group.name" />

        <mega-number
          label="Sort Order"
          :step="100"
          v-model="group['sort_order']"
        />
      </div>

      <ul class="menu-list">
        <li class="menu-item">
          <mega-switch
            class="w-100 bg-success mb-0"
            label="Group Visibility"
            @click="group.is_active = !group.is_active"
            :checked="group.is_active"
          />
        </li>
      </ul>

      <div class="card-body">
        <div
          class="navbar sm-down:flex-column-reverse md-up:justify-content-end"
        >
          <mega-button
            class="btn-white text-danger sm-down:w-100 sm-down:mt-3"
            @click="deleteGroup"
            >Delete</mega-button
          >
          <mega-button
            class="btn-white sm-down:w-100 sm-down:mt-3 md-up:mr-2 md-up:ml-auto"
            @click="modal = false"
            >Close</mega-button
          >
          <mega-button
            class="btn-primary sm-down:w-100"
            :disabled="!group.name"
            @click="submit"
            >Save</mega-button
          >
        </div>
      </div>
    </mega-modal>

    <delete-confirm
      ref="delete-group"
      title="Delete group"
      text="Confirm that you are sure you want to delete the group"
      @confirm="removeGroup"
    />
  </div>
</template>

<script>
import DeleteConfirm from "../../../components/delete-confirm";

export default {
  data() {
    return {
      group: {},
      modal: false,
      loaded: false
    };
  },
  methods: {
    deleteGroup() {
      this.$refs["delete-group"].open();
    },

    submit() {
      this.$api.v2.put("/group", { ...this.group }).then(() => {
        this.modal = false;
        this.$parent.$refs.groups.update();
      });
    },

    removeGroup(modal) {
      let params = {
        idt_group: this.group.idt_group
      };

      this.$api.v2.delete("/group", { params }).then(() => {
        modal.close();
        this.modal = false;

        this.$emit("delete", this);
      });
    },

    open() {
      this.modal = true;

      let params = {
        idt_group: this.$parent.groupId
      };

      this.$api.v2.get("/group", { params }).then(response => {
        this.loaded = true;
        this.group = response.data;
      });
    }
  },
  components: {
    DeleteConfirm
  }
};
</script>
