var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "mega-modal",
        {
          ref: "modal-2",
          staticClass: "modal-sm",
          attrs: { active: _vm.modal, loading: !_vm.loaded },
          on: {
            onClose: function($event) {
              _vm.modal = false
            }
          }
        },
        [
          _c(
            "mega-header",
            { staticClass: "bg-lighter", attrs: { title: "Edit group" } },
            [
              _c("span", { staticClass: "text-muted" }, [
                _vm._v("Enter group details.")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("mega-input", {
                attrs: { label: "Group Name" },
                model: {
                  value: _vm.group.name,
                  callback: function($$v) {
                    _vm.$set(_vm.group, "name", $$v)
                  },
                  expression: "group.name"
                }
              }),
              _vm._v(" "),
              _c("mega-number", {
                attrs: { label: "Sort Order", step: 100 },
                model: {
                  value: _vm.group["sort_order"],
                  callback: function($$v) {
                    _vm.$set(_vm.group, "sort_order", $$v)
                  },
                  expression: "group['sort_order']"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "menu-list" }, [
            _c(
              "li",
              { staticClass: "menu-item" },
              [
                _c("mega-switch", {
                  staticClass: "w-100 bg-success mb-0",
                  attrs: {
                    label: "Group Visibility",
                    checked: _vm.group.is_active
                  },
                  on: {
                    click: function($event) {
                      _vm.group.is_active = !_vm.group.is_active
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              {
                staticClass:
                  "navbar sm-down:flex-column-reverse md-up:justify-content-end"
              },
              [
                _c(
                  "mega-button",
                  {
                    staticClass:
                      "btn-white text-danger sm-down:w-100 sm-down:mt-3",
                    on: { click: _vm.deleteGroup }
                  },
                  [_vm._v("Delete")]
                ),
                _vm._v(" "),
                _c(
                  "mega-button",
                  {
                    staticClass:
                      "btn-white sm-down:w-100 sm-down:mt-3 md-up:mr-2 md-up:ml-auto",
                    on: {
                      click: function($event) {
                        _vm.modal = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c(
                  "mega-button",
                  {
                    staticClass: "btn-primary sm-down:w-100",
                    attrs: { disabled: !_vm.group.name },
                    on: { click: _vm.submit }
                  },
                  [_vm._v("Save")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("delete-confirm", {
        ref: "delete-group",
        attrs: {
          title: "Delete group",
          text: "Confirm that you are sure you want to delete the group"
        },
        on: { confirm: _vm.removeGroup }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }