<template>
  <mega-modal
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <mega-card class="p-4">
      <mega-input
        :label="$t('exclusive_label_ru')"
        :placeholder="$t('exclusive_label_ru')"
        v-model="ru_label"
      />
      <mega-input
        :label="$t('exclusive_label_en')"
        :placeholder="$t('exclusive_label_en')"
        v-model="en_label"
      />
      <div class="ml-auto d-flex w-100">
        <span class="btn btn-light ml-auto mr-2" @click="close()">
          {{ $t("cancel") }}
        </span>
        <span class="btn btn-success" @click="submit()">
          {{ $t("model_save") }}
        </span>
      </div>
    </mega-card>
  </mega-modal>
</template>

<script>
export default {
  props: ["round", "enabled", "likes"],
  data() {
    return {
      modal: false,
      ru_label: "",
      en_label: ""
    };
  },
  methods: {
    submit() {
      this.$api.v2base
        .put("/model/exclusive/labels", {
          label_ru: this.ru_label,
          label_en: this.en_label
        })
        .then(() => {
          this.$alert.success(this.$t("model_updated"));
          this.ru_label = "";
          this.en_label = "";
          this.close();
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    }
  }
};
</script>
