<template>
  <main class="models-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <span
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click="addModel"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("new_model") }}</span>
          </span>

          <span
            v-if="type_id > 3"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click="addGroup"
          >
            <i class="i-folder float-left"></i>
            <span>{{ $t("new_group") }}</span>
          </span>

          <span
            class="btn btn-sm ml-auto btn-light text-left d-block sm-down:w-fill"
            v-if="type_id > 3"
            @click="editGroup"
          >
            <i class="i-settings float-left"></i>
            <span>{{ $t("edit_group") }}</span>
          </span>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5">
        <div class="row">
          <div v-show="type_id > 3" class="md-down:col-12 lg:col-4 xl:col-3">
            <section-groups :route-group="group" ref="groups" />
          </div>

          <div class="col">
            <nav
              class="nav sm-down:flex-column mb-3 flex-wrap"
              style="padding: 3px 0;"
            >
              <span
                class="btn text-left d-block sm-down:w-fill"
                :class="{ 'btn-primary': is_active === 'all' }"
                @click="active_bool = undefined"
                >{{ $t("all") }}</span
              >

              <span
                class="btn text-left d-block sm-down:w-fill"
                :class="{ 'btn-primary': is_active === 'active' }"
                @click="active_bool = true"
                >{{ $t("active") }}</span
              >

              <span
                class="btn text-left d-block sm-down:w-fill"
                :class="{ 'btn-primary': is_active === 'disabled' }"
                @click="active_bool = false"
                >{{ $t("disabled") }}</span
              >

              <router-link
                class="btn text-left d-block sm-down:w-fill"
                :class="{ 'btn-primary': is_active === 'exclusive' }"
                :to="{ name: 'Models', params: { is_active: 'exclusive' } }"
                >{{ $t("model_exclusive") }}</router-link
              >

              <span
                class="ml-auto btn text-left d-block sm-down:w-fill btn-arround"
                v-if="$route.params.is_active == 'exclusive'"
                @click="$refs.exclusive_modal.open()"
              >
                {{ $t("exclusive_label") }}
              </span>
            </nav>

            <draggable
              v-model="$root.list"
              :animation="200"
              class="row"
              v-if="list.length"
              @change="changeModelWeight($event)"
            >
              <div
                class="sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4"
                v-for="(model, i) in displayed_list"
                :key="i"
              >
                <model-card :data="model" @activeToggle="clearData" />
              </div>
            </draggable>

            <mega-loading
              :identifier="loadingId"
              @infinite="loadPage($event)"
            ></mega-loading>
          </div>
        </div>
      </div>
    </section>

    <new-model ref="new-model" />
    <new-group ref="new-group" />
    <edit-group ref="edit-group" :group="groupId" @delete="onGroupDelete" />
    <exclusive-modal ref="exclusive_modal" />
  </main>
</template>

<script>
import ModelCard from "./components/model-card";
import SectionGroups from "./sections/groups";
import NewGroup from "../../components/new_group";
import EditGroup from "./components/edit_group";
import NewModel from "../../components/new_model";
//import PlaceholderModelCard from "../../placeholders/model-card";
import draggable from "vuedraggable";
import exclusiveModal from "./components/exclusive-modal";
//import ExclusiveModal from "./components/exclusive-modal.vue";

export default {
  name: "ModelsPage",
  props: ["groupId", "roundType", "is_active"],
  data() {
    return {
      group: this.groupId > -1 ? parseInt(this.groupId) : undefined,
      loadingId: +new Date()
    };
  },
  watch: {
    $route() {
      this.group = this.groupId > -1 ? parseInt(this.groupId) : undefined;

      this.clearData();
    }
  },
  computed: {
    list() {
      return this.$root.list;
    },
    displayed_list() {
      return this.list.filter(model => model.idt_model && model.modifications);
    },
    active_bool: {
      get() {
        let act = undefined;

        if (this.is_active === "active") act = true;

        if (this.is_active === "disabled") act = false;

        return act;
      },
      set(value) {
        let query = this.$route.query;
        let params = {
          is_active: value === undefined ? "all" : value ? "active" : "disabled"
        };

        this.$router.push({ query, params });
      }
    },
    type_id() {
      let res = undefined;

      if (this.roundType === "image") res = 2;
      if (this.roundType === "video") res = 3;
      if (this.roundType === "3d") res = 4;
      if (this.roundType === "gif") res = 5;
      if (this.roundType === "mask") res = 10;
      if (this.roundType === "portal") res = 12;

      return res;
    }
  },
  created() {
    if (!this.$root["history"].includes(this.$route.name))
      this.$root["list"] = [];
  },
  mounted() {
    this.$navbar.name = this.$t("models");
    if (this.type_id > 3) {
      this.$refs["groups"].load(this.type_id);
    }

    console.log("Active:", this.is_active);
  },
  methods: {
    editGroup() {
      this.$refs["edit-group"].open();
    },
    addModel() {
      this.$refs["new-model"].open({ type: this.type_id, group: this.group });
    },
    addGroup() {
      this.$refs["new-group"].open({ type: this.type_id });
    },
    onGroupDelete() {
      let query = this.$route.query;
      let params = {
        roundType: this.roundType,
        is_active: this.is_active,
        groupId: null
      };

      this.$router.push({ query, params });

      this.$refs["groups"].load(this.type_id);
    },

    loadPage($state) {
      let is_active =
        this.is_active == "active"
          ? true
          : this.is_active == "disabled"
          ? false
          : undefined;
      let exclusive = this.is_active == "exclusive" ? true : false;

      let params = {
        is_active,
        exclusive,
        limit: 60,
        idc_round_type: this.type_id,
        offset: this.list.length,
        idt_user: this.$route.query.user,
        idt_arp: 0,
        sn_id: this.$store.getters.selectedSnId
      };

      if (this.group > -1) params.idt_group = this.group;

      this.$api.v2
        .get("/models", { params })
        .then(({ data }) => {
          if (data.models.length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.$root.list.push(...data.models);
            const params = {
              sn_id: this.$store.getters.selectedSnId
            };
            this.$api.v2base
              .get("/model/count", { params })
              .then(({ data }) => {
                this.$navbar.name = `${this.$t("models")} (${data.count})`;
              });
          } else if ($state) $state.complete();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    clearData() {
      this.$refs["groups"].load(this.type_id);
      this.$root.list = [];
      this.loadingId += 1;
    },

    changeModelWeight(e) {
      console.log(e);
      this.$root.list[e.moved.newIndex]["sort_order"] =
        this.$root.list[e.moved.newIndex + 1]["sort_order"] + 100;
    }
  },
  components: {
    ModelCard,
    NewModel,
    NewGroup,
    EditGroup,
    SectionGroups,
    //PlaceholderModelCard,
    draggable,
    exclusiveModal
  }
};
</script>
