var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: true },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "mega-card",
        { staticClass: "p-4" },
        [
          _c("mega-input", {
            attrs: {
              label: _vm.$t("exclusive_label_ru"),
              placeholder: _vm.$t("exclusive_label_ru")
            },
            model: {
              value: _vm.ru_label,
              callback: function($$v) {
                _vm.ru_label = $$v
              },
              expression: "ru_label"
            }
          }),
          _vm._v(" "),
          _c("mega-input", {
            attrs: {
              label: _vm.$t("exclusive_label_en"),
              placeholder: _vm.$t("exclusive_label_en")
            },
            model: {
              value: _vm.en_label,
              callback: function($$v) {
                _vm.en_label = $$v
              },
              expression: "en_label"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ml-auto d-flex w-100" }, [
            _c(
              "span",
              {
                staticClass: "btn btn-light ml-auto mr-2",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("model_save")) + "\n      ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }